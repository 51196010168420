// Text
.text-center {
  text-align: center;
}
.text-bold {
  font-weight: bold;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-docorate-none {
  text-decoration: none;
}

// Positions ******************
.fixed-top {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 4;
}

.fixed-top-74px {
  position: -webkit-sticky;
  position: sticky;
  top: 7.4rem;
  z-index: 1;
  // height: calc(100vh - 7.4rem);
  // overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }
}
.top {
  &-74px {
    top: 7.4rem;
  }
}

.max-width-650px {
  max-width: 65rem;
}
.max-width-500px {
  max-width: 50rem;
}

.max-width-550px {
  max-width: 55rem;
}
.max-width-300px {
  max-width: 30rem;
}

.width-300px {
  width: 30rem;
}

.width-200px {
  width: 20rem;
}

.width-100-percent {
  width: 100%;
}

// Margins ******************
.h-100 {
  height: 100%;
}
.m {
  &-y-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  &-y-40px {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  &-y-30px-15px {
    margin-top: 3rem;
    margin-bottom: 1.5rem;
  }

  &-y-20px {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  &-y-10px {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  &-y-8px {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
  }

  &-x-auto {
    margin-inline: auto;
    display: block;
  }

  &-l-auto {
    margin-left: auto;
  }
  &-l-30px {
    margin-left: 1.5rem;
  }
  &-l-20px {
    margin-left: 1.5rem;
  }
  &-l-15px {
    margin-left: 1.5rem;
  }

  &-l-5px {
    margin-left: 0.5rem;
  }

  &-r-120px {
    margin-right: 12rem;
  }
  &-r-110px {
    margin-right: 11rem;
  }
  &-r-25px {
    margin-right: 2.5rem;
  }

  &-r-15px {
    margin-right: 1.5rem;
  }

  &-r-10px {
    margin-right: 1rem;
  }

  &-r-6px {
    margin-right: 0.6rem;
  }

  &-r-0 {
    margin-right: 0;
  }

  &-t-150px {
    margin-top: 15rem;
  }

  &-t-100px {
    margin-top: 10rem;
  }

  &-t-70px {
    margin-top: 7rem;
  }

  &-t-60px {
    margin-top: 6rem;
  }

  &-t-50px {
    margin-top: 5rem;
  }
  &-t-40px {
    margin-top: 4rem;
  }
  &-t-30px {
    margin-top: 3rem;
  }
  &-t-20px {
    margin-top: 2rem;
  }

  &-t-25px {
    margin-top: 2.5rem;
  }

  &-t-20px {
    margin-top: 2rem;
  }

  &-t-10px {
    margin-top: 1rem;
  }

  &-t-8px {
    margin-top: 0.8rem;
  }

  &-t-6px {
    margin-top: 0.6rem;
  }

  &-t-5px {
    margin-top: 0.5rem;
  }
  &-t-2px {
    margin-top: 0.2rem;
  }
  &-t-1px {
    margin-top: 0.1rem;
  }

  &-t-auto {
    margin-top: auto;
  }

  &-b-50px {
    margin-bottom: 5rem;
  }
  &-b-40px {
    margin-bottom: 4rem;
  }

  &-b-35px {
    margin-bottom: 3.5rem;
  }
  &-b-30px {
    margin-bottom: 3rem;
  }

  &-b-25px {
    margin-bottom: 2.5rem;
  }

  &-b-20px {
    margin-bottom: 2rem;
  }

  &-b-15px {
    margin-bottom: 1.5rem;
  }
  &-b-12px {
    margin-bottom: 1.2rem;
  }
  &-b-10px {
    margin-bottom: 1rem;
  }
  &-b-8px {
    margin-bottom: 0.8rem;
  }
  &-b-7px {
    margin-bottom: 0.7rem;
  }
  &-b-6px {
    margin-bottom: 0.6rem;
  }
  &-b-5px {
    margin-bottom: 0.5rem;
  }
  &-b-2px {
    margin-bottom: 0.2rem;
  }
}

// Padding *************
.p {
  &-y-100px {
    padding: {
      top: 10rem;
      bottom: 10rem;
    }
  }
  &-y-60px {
    padding: {
      top: 6rem;
      bottom: 6rem;
    }
  }
  &-y-50px {
    padding: {
      top: 5rem;
      bottom: 5rem;
    }
  }
  &-y-20px {
    padding: {
      top: 2rem;
      bottom: 2rem;
    }
  }

  &-0 {
    padding: 0;
  }
}

// Color ********
.bg-1 {
  background-color: #f6f7f9;
}

.color-primary {
  color: $color-9;
}

.color-white {
  color: $color-white;
}

// Display ******
.display-block {
  display: block;
}
.display-none {
  display: none;
}

// Media Queries
.mq-phone {
  @include responsive(phone) {
    display: none;
  }
}

// Flex layouts********
.flex-rw-jcb-aic {
  @include flex-rowwrap-jcbetween-aicenter;
}
.flex-r-jccenter {
  @include flex-row-jccenter;
}
.flex-r-jcbetween {
  @include flex-row-jcbetween;
}
.flex-r-jcend {
  @include flex-row-jcend;
}
.flex-r-aicenter {
  @include flex-row-aicenter;
}

.flex-r-jcbetween-aicenter {
  @include flex-row-jcbetween-aicenter;
}
.flex-r-jccenter-aicenter {
  @include flex-row-jccenter-aicenter;
}

.flex-r {
  @include flex-row;
}
.flex-c {
  @include flex-column;
}
.flex-c-jccenter-aicenter {
  @include flex-column-jccenter-aicenter;
}
.flex-c-jcbetween {
  @include flex-column-jcbetween;
}

// Flex utility classes
.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-gap-5px {
  gap: 0.5rem;
}

.flex-gap-30px {
  gap: 3rem;
}

.flex-gap-10px {
  gap: 1rem;
}

.flex-grow-1 {
  flex-grow: 1;
}

.align-self-center {
  align-self: center;
}

.align-self-start {
  align-self: flex-start;
}

.rotate-270 {
  transform: rotate(270deg);
  transition: 0.4s;
}

.rotate--270 {
  transform: rotate(-270deg);
  transition: 0.4s;
}

.rotate-0 {
  transform: rotate(0);
  transition: 0.4s;
}

.rotate--180 {
  transform: rotate(-180deg);
  transition: 0.4s;
}
.link-decoration-none {
  text-decoration: none;
}
