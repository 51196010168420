@keyframes slowLoad {
  0% {
    visibility: hidden;
    opacity: 0;
  }
  100% {
    visibility: visible;
    opacity: 1;
  }
}

.modal-layout {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.322);
  z-index: 999;
  animation: slowLoad 0.4s linear;
  overflow-y: auto;
  padding-bottom: 5rem;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  @supports (backdrop-filter: blur(5px)) {
    backdrop-filter: blur(1px);
  }
}
