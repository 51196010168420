.ongoing-course {
  background: $color-white;
  border-radius: 0.4rem;
  padding: 2rem;
  @include flex-row-jcbetween-aicenter;
  gap: 2rem;

  @include responsive(tab-land-small) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__content {
    flex-basis: 44rem;
    flex-grow: 0;

    @include responsive(tab-land-small) {
      flex-basis: auto;
    }
  }
}
