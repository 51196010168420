.payment-card {
  &__wrapper {
    display: flex;
    justify-content: center;
  }

  background-color: $color-white;
  border-radius: 0.7rem;
  padding: 4rem 6rem;
  flex-basis: 62rem;

  &__items {
    @include flex-column;
    gap: 3rem;
  }

  &__item {
    input[type="radio"] {
      display: none;
    }

    input[type="radio"] + label {
      @include flex-row-aicenter;
      gap: 1.5rem;
      cursor: pointer;
    }

    &-radio {
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      border: 0.3rem solid $color-49;
      display: grid;
      place-items: center;
      position: relative;
      flex-shrink: 0;

      &::before {
        position: absolute;
        content: "";
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        background-color: transparent;
        transition: .4s;
      }
    }

    &-detail {
      background: $color-white;
      border: 0.751334px solid rgba(100, 115, 130, 0.15);
      box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.03);
      border-radius: 7px;
      padding: 2rem;
      user-select: none;

      @include flex-row-aicenter;
      flex-grow: 1;
      gap: 2rem;
    }

    input[type="radio"]:checked + label .payment-card__item-radio::before {
      background-color: $color-49;
    }
  }

  &__logos {
    @include flex-row-aicenter;
    gap: 1rem;
  }

  &__form{
    padding-left: 3.5rem;
  }
}
