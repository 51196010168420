.input {
  &__search-course {
    @include flex-row-aicenter;
    background-color: $color-white;
    border: 1px solid $color-26;
    border-radius: 0.4rem;

    width: 42rem;
    margin-left: auto;
    margin-right: 2rem;

    &--no-margin {
      margin-left: 0;
      margin-right: 0;
    }

    &.width-fluid {
      width: auto;
      margin-right: auto;
    }
  }

  &__search-icon {
    width: 1.3rem;
    height: 1.3rem;
    margin: {
      left: 1.5rem;
      right: 1.5rem;
    }
  }

  &__search-item {
    flex-grow: 1;
    padding: 1.2rem 0.5rem 1.2rem 0;
    border: none;
    font-family: inherit;

    font-size: 1.6rem;
    line-height: 1.2rem;

    &::placeholder {
      color: $color-27;
    }

    &:focus {
      outline: none;
    }
  }
}
