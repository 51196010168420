.grid-view {
  display: grid;
  grid-column-gap: 3rem;

  &__equal {
    &--2 {
      grid-template-columns: repeat(2, 1fr);
    }
    &--3 {
      grid-template-columns: repeat(3, 1fr);
    }
    &--4 {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  &__minMax {
    grid-template-columns: 71% 25%;
    grid-column-gap: 4%;
  }

  @include responsive(big-phone) {
    grid-template-columns: repeat(1, 1fr);
  }
}
