.welcome {
  &__card {
    border-radius: 1rem;

    padding: 5rem 2.5rem;
    background-image: url(../../img/pic-5.png);
    background-repeat: no-repeat;
    background-position: top right;
    background-size: cover;

    @include responsive(tab-port) {
      padding: 4rem 2rem;
    }
  }

  &__username {
    text-transform: capitalize;
  }
}
