.schedule {
  background-color: $color-white;
  border-radius: 0.4rem;
  max-height: 100vh;
  overflow-y: scroll;

  &__title {
    padding: 3rem;
    border-bottom: 1px solid $color-29;
  }
  &__activities {
    padding: 4rem 2.5rem 3.5rem;
  }
  &__events{
      padding-top: 4rem;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.schedule::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.schedule {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
