.review {
  &__card {
    padding: 2rem;
    background: $color-white;
    border-radius: 1rem;
  }

  &__rating {
    @include flex-row-aicenter;
    background: $color-white;
    border: 1px solid $color-17;
    box-shadow: 0 0.5rem 1rem $color-18;
    border-radius: 1rem;
    padding: 1.2rem;

    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }

  &__image-holder {
    max-width: 8rem;
    max-height: 8rem;
    border: 1px solid $color-19;
    border-radius: 1rem;
    overflow: hidden;
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 1rem;
  }

  &__name {
    margin-left: 1.5rem;
  }
  &__title {
    margin-left: 2rem;
  }
  &__icon-holder {
    max-width: 5.2rem;
    max-height: 5.3rem;
    border-radius: 0.8rem;
    flex-shrink: 0;
  }
  &__icon {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &__link-arrow {
    width: 2rem;
    height: 2rem;
  }
}

.mmmm {
  fill: green !important;

  path:nth-child(1) {
    fill: yellow !important;
  }
}
