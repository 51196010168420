.card-content {
  // height: 400px;
  background-color: $color-white;
  border-radius: 0.4rem;

  &__top {
    @include flex-row-jcbetween-aicenter;
    padding: 2rem 2rem 1rem;
    border-bottom: 1px solid $color-29;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 2.5rem 2rem 3.5rem;

    &.no-padding {
      padding: 0;
    }
  }

  &:not(:last-child) {
    margin-bottom: 3rem;
  }
}
