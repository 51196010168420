.payments {
  &__wrapper {
    flex-basis: 52rem;
    background-color: $color-white;
    border-radius: 0.7rem;
    padding: 6rem 2rem;
  }
  &__card {
    @include flex-column-jcbetween-aicenter;
    gap: 1rem;
  }
}
