.course-enroll-card {
  padding-bottom: 3rem;
  &__avatar {
    height: 19.4rem;
  }
  &__ele {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  &__actions {
    // padding: 2.5rem 2rem 3.5rem;
    padding: 2.5rem 2rem 0;
  }
}
