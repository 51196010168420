.alert-notification {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1000;
  padding: 1rem;
  border-radius: 0.4rem 0 0 0.4rem;
  // background-color: $color-white;
  background-color: rgba(255, 255, 255, 0.00001);
  // box-shadow: 0 1rem 1.5rem rgba(0, 0, 0, 0.08);

  width: 30rem;
  //   height: 20rem;
  @include flex-column;
  gap: 0.6rem;
  // display: none;

  // Disable scrobal

  &__item {
    @include flex-row;
    // justify-content: flex-start;
    gap: 0.8rem;
    padding: 1rem 1rem;
    background-color: $color-white;
    border-radius: 0.4rem;
    box-shadow: 0 0.7rem 1.2rem rgba(0, 0, 0, 0.08);
    position: relative;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      background-color: #e1e1e174;
      width: 100%;
      height: 1.2rem;
      transition: 0.5s;
      animation: expandFullWidth 5s linear;
    }
  }

  &__content {
    flex-grow: 1;
    @include flex-row-jcbetween;
    gap: 0.7rem;
  }

  &__icon {
    width: 4.7rem;
    height: 4.7rem;
  }

  &__title {
    font-style: normal;
    font-weight: bold;
    font-size: 1.3rem;
    line-height: 1.6rem;
    color: #272d33;
    &.bad {
      color: #d82a2a;
    }
  }
  &__description {
    font-style: normal;
    font-weight: normal;
    font-size: 1.35rem;
    line-height: 1.5rem;
    color: $color-16;
    &.bad {
      color: #e27373;
    }
  }

  &__close-btn {
    background-color: #5c4fff;
    color: $color-white;
    font-family: inherit;
    font-size: 1.2rem;
    padding: 0.25rem 1.05rem;
    border-radius: 0.4rem;
    margin-left: auto;
    display: block;
    &.bad {
      background-color: #e27373;
    }
  }
}
