.discussion {
  @include flex-row;
  padding: {
    top: 1.8rem;
    bottom: 1.8rem;
  }

  &__image {
    max-width: 3.2rem;
    max-height: 3.2rem;
    border-radius: 50%;
  }

  &__img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }

  &__details {
    flex-grow: 1;
    margin-left: 1rem;
  }

  &__age {
    display: inline-block;
    font-weight: normal;
    font-size: 1.2rem;
    line-height: 1.4rem;
    color: $color-16;
    margin: {
      top: 1rem;
      bottom: 1.5rem;
    }
  }

  &:not(:last-child) {
    border-bottom: 1px solid $color-29;
  }
}
