// ****************** Colors ******************
$color-white: #ffffff;
$color-black: #000000;
$background-color-1: #f6f7f9;
$background-color-2: #e5e5e5;

$color-1: #0d1b281a;
$color-2: #00000018;
$color-3: #ebebff;
$color-4: #c4cdd526;
$color-5: #303037;
$color-6: #272d33;
$color-7: #272d33b3;
$color-8: #272d331a;
$color-9: #5c4fff;
$color-10: #0d028229;
$color-11: #231d72;
$color-12: #0d02820a;
$color-13: #272d3391;
$color-14: #c0c0c0;
$color-15: #979797;
$color-16: #647382;
$color-17: #e8e8e866;
$color-18: #0d028208;
$color-19: #e8e8e8;
$color-20: #f7b50c;
$color-21: #6473822b;
$color-22: #5c4fff1a;
$color-23: #64738212;
$color-24: #00d564;
$color-25: #b82626;
$color-26: #6473821a;
$color-27: #64738280;
$color-28: #ec0b43;
$color-29: #ecf0f2;
$color-30: #04b8001a;
$color-31: #5c4fff80;
$color-32: #f2f6f9;
$color-33: #989898;
$color-34: #e5fde680;
$color-35: #00d5643d;
$color-36: #ede4ff;
$color-37: #5c4fff1a;
$color-38: #ff05670f;
$color-39: #ff05671a;
$color-40: #231f20;
$color-41: #ff0567;
$color-42: #6473823e;
$color-43: #828282;
$color-44: #e4e2e2;
$color-45: #5c4fff33;
$color-46: #161616;
$color-47: #6473821a;
$color-48: #75808a;
$color-49: #9c9c9c;
$color-50: #191c1f;
$color-51: #121212;
$color-52: #cbcbcb;
$color-53: #0c0c0c;
$color-54: #64738226;
$color-55: #00000004;
$color-56: #f5f7ff;
$color-57: #5c4fff4d;
$color-58: #b2b9c0;
$color-59: #e7eaed;
$color-60: rgba(232, 232, 232, 0.7);
$color-61: rgba(100, 115, 130, 0.5);
$color-62: rgba(100, 115, 130, 0.2);

$main-font: stevia-regular, "Averta CY W01";

// ****************** Grids ******************
$row-width: 125rem;
$row-width-2: 125rem;
