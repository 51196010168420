.progress-bar-with-user {
  background-color: $color-30;
  border-radius: 6rem;
  position: relative;
  height: 0.6rem;

  &__status {
    border-radius: 6rem;
    width: 33%;
    background-color: $color-24;
    position: absolute;
    left: 0;
    top: 0;
    height: 0.6rem;
  }

  &__bottom {
    @include flex-row-jcbetween;
    margin-top: 1rem;
  }

  &__percent {
    color: $color-16;
    margin-top: 0.5rem;
    display: inline-block;

    font-size: 1.2rem;
    line-height: 1.5rem;
  }
}
