.enrolled-student {
  @include flex-row-aicenter;

  &__item {
    border-radius: 50%;

    &--1 {
      max-width: 3.3rem;
      max-height: 3.3rem;

      &.sm {
        max-width: 1.8rem;
        max-height: 1.8rem;
      }
    }
    &--last {
      width: 3.3rem;
      height: 3.3rem;
      background-color: $color-9;
      display: grid;
      place-items: center;
      color: $color-white;
      font-weight: 600;
      font-size: 1.2rem;

      &.sm {
        max-width: 1.8rem;
        max-height: 1.8rem;
        font-size: .7rem;
      }
    }

    &:not(:first-child) {
      margin-left: -0.6rem;
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
}
