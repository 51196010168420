.activities {
  display: grid;
  grid-template-columns: 25% 73%;
  grid-gap: 3.5rem 2%;
  margin-top: 4.5rem;

  @include responsive(tab-land-mini) {
    grid-template-columns: repeat(1, 1fr);
  }

  &__actions {
    // background-color: red;
    // padding: 3rem;
    & > * {
      display: block;
      text-decoration: none;
      color: initial;
      &:not(:last-child) {
        margin-bottom: 1rem;
      }
    }

    @include responsive(tab-land-mini) {
      display: flex;
      gap: 1.5rem;
      overflow: auto;

      &::-webkit-scrollbar {
        display: none;
      }

      & > * {
        flex-shrink: 0;
        &:not(:last-child) {
          margin-bottom: 0;
        }
      }
    }
  }
  &__action {
    @include flex-row-aicenter;
    gap: 2.4rem;
    background: $color-white;
    border-radius: 0.4rem;
    padding: 1rem;
    cursor: pointer;
    transition: 0.4s;
    border: 1px solid transparent;

    // &:not(:last-child) {
    // margin-bottom: 1rem;
    // }

    &.active {
      border-color: $color-31;
      transition: 0.4s;

      .activities__name {
        color: $color-9;
        transition: 0.4s;
      }

      &.stroke {
        path {
          stroke: $color-9;
          transition: 0.4s;
        }
      }
      &.fill {
        path {
          fill: $color-9;
          transition: 0.4s;
        }
      }
      .activities__icon-link {
        stroke: $color-9;
        fill: $color-9;
      }
    }
  }

  &__icon {
    max-width: 2.2rem;
    max-height: 2.2rem;
    // margin-right: 2.4rem;

    &-2 {
      max-width: 3.2rem;
      max-height: 2rem;
      margin-right: 1.5rem;
    }
    &-link {
      max-width: 2.2rem;
      max-height: 2.2rem;
    }
  }
  &__name {
    font-weight: normal;
    font-size: 1.6rem;
    line-height: 3.2rem;
  }
  &__events {
    // background-color: $color-white;
    // padding: 3rem;
  }
}
