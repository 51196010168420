.custom-dropdown {
  background: $color-white;
  border: 1px solid $color-17;
  box-shadow: 0 0.5rem 1rem $color-18;
  border-radius: 1rem;
  overflow: hidden;

  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }
  &__toggler {
    background-color: transparent;
    border: none;
    cursor: pointer;
    @include flex-row-aicenter;
    padding: 1.2rem;
    border-radius: 1rem;
    font-family: inherit;
    width: 100%;

    &:active,
    &:focus {
      outline: none;
    }

    &>svg{
      flex-shrink: 0;
    }
  }

  &__icon-holder {
    border-radius: 0.8rem;
    overflow: hidden;
    flex-shrink: 0;
  }

  &__title {
    margin-left: 0.8rem;
    text-align: left;
  }

  &__content {
    padding: 0 1.2rem;
    padding: {
      bottom: 1.5rem;
    }
  }
}
