.course-content-dropdown {
  &__wrapper {
    @include flex-column;
    gap: 1.3rem;
  }
  &__toggler {
    @include flex-row;
    @extend .btn;
    gap: 1rem;
    background: rgba(100, 115, 130, 0.07);
    border-radius: 0.8rem;
    padding: 1.5rem;
    width: 100%;

    & > svg {
      flex-shrink: 0;
    }
  }
  &__lists {
    padding-left: 3.4rem;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    position: relative;
    @include flex-column;
    gap: 3rem;

    &::before {
      content: "";
      position: absolute;
      width: 1px;
      height: calc(100% - 9rem);
      top: calc(2.5rem + 1.3rem);
      left: calc(3.4rem + 6px);
      background-color: $color-29;
      flex-shrink: 0;
    }
  }
  &__list-item {
    @include flex-row;
    gap: 0.8rem;
    flex-shrink: 0;

    a {
      text-decoration: none;
    }
  }
  // Make the left side line more dynamic by putting all it
  // Values in a vairiable
  &__icon {
    transition: 0.4s;
    z-index: 1;
    flex-shrink: 0;
  }
}

.mnm {
  margin-top: -0.6rem;
}
