.rating {
  &__stars {
    @include flex-row-aicenter;
    gap: 0.5rem;
    margin-top: 1rem;
  }

  &__star {
    // cursor: pointer;
    width: 2.4rem;
    height: 2.2rem;
  }
}
