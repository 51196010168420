.course-with-price {
  &__thumbnail {
    // height: 26.4rem;
    height: 15.4rem;
    border-radius: 0.8rem;
    overflow: hidden;
    &-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
