.course-date-selected {
  background: rgba(92, 79, 255, 0.03);
  border: 1px solid rgba(92, 79, 255, 0.5);
  border-radius: 0.8rem;
  width: 100%;
  padding: 1rem 1.5rem;
  margin-bottom: 2rem;

  &__title {
    @include flex-row-aicenter;
    color: #5c4fff;
  }
}

.icon-info-circle-blue {
  width: 1.6rem;
  height: 1.6rem;
}
