.live-classes {
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
}

.liveClassTop {
  @include flex-rowwrap-jcbetween-aicenter;
  margin: 1.6rem 0 0;
}

.liveClassTopBtn {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 1.6rem;

  @include responsive(tab-port) {
    margin: 2rem 0 0;
  }
}

.liveClassBlock {
  @include flex-row-jcbetween-aicenter;
  padding: 2.5rem 2rem 2.5rem;
  cursor: pointer;

  @include responsive(tab-port) {
    flex-direction: column-reverse;
    gap: 2rem;
    align-items: flex-start;
  }
}

.liveClassBlock1 {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-column-gap: 4.2rem;
  align-self: flex-start;

  @include responsive(tab-port) {
    grid-column-gap: 1rem;
  }
}

.liveClassInfo {
  display: grid;
  grid-row-gap: 0.3rem;
}

.liveClassTitle {
  padding: 0;
  font-size: 1.6rem;

  @include responsive(tab-port) {
    margin: 2rem 0 0;
  }
}

.liveClassBlock2 {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-column-gap: 2rem;
  align-self: flex-start;
  @include responsive(tab-port) {
    grid-column-gap: 1rem;
  }
}

.joinEventBox {
  justify-self: center;
  justify-content: center;
  width: max-content;
  margin: auto;
}

.joinEventGrid {
  display: grid;
  justify-content: center;
  border-top: 0.1rem solid $color-60;
  padding: 4.4rem;
}

.liveClassGrid {
  padding: 0;
}

.meetupTyp {
  background: #f5f5f5;
  border-radius: 0.4rem;
  padding: 0.8rem;
  font-size: 1.2rem;
  font-weight: 400;
  color: $color-black;
  cursor: pointer;
}

.liveClassList {
  margin: 2.5rem 0 0;
  display: grid;
}

.liveClassDate {
  background: #ffffff;
  border: 0.1rem solid #eff0ff;
  border-radius: 0.5rem;
  height: 4.5rem;
  width: 4.5rem;
  grid-row-gap: 0.1.6rem;
}

.dropdownBtn {
  background: unset;
  border: none;
  padding: 0;
  margin: 0;
  position: relative;
  cursor: pointer;
}

.dropdownBtnFlip {
  background: unset;
  border: none;
  padding: 0;
  margin: 0;
  position: relative;
  cursor: pointer;
}

.dropdownBtn::before {
  content: "";
  height: 1.4rem;
  width: 1.4rem;
  border-top: 0.3rem solid $color-16;
  border-right: 0.3rem solid $color-16;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  transform: rotate(45deg);
}

.dropdownBtnFlip::before {
  content: "";
  height: 1.4rem;
  width: 1.4rem;
  border-top: 0.3rem solid $color-16;
  border-right: 0.3rem solid $color-16;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  transform: rotate(140deg);
}

.uploadModalInfo {
  justify-content: unset;
  grid-template-columns: unset;
}

.uploadModalContent {
  display: grid;
  grid-auto-flow: unset;
  grid-row-gap: 4rem;
}

.uploadLinkColumn {
  display: grid;
  grid-row-gap: 1.6rem;
}

.linkTitle {
  font-weight: 400;
  color: #272d33;
  font-size: 1.6rem;
}

.linkUploadBox {
  background: rgba(100, 115, 130, 0.02);
  border: 0.1rem solid $color-61;
  border-radius: 0.4rem;
  outline: none;
  padding: 1.6rem;
}

.uploadModalInfo button.btn.btn-1 {
  justify-self: start;
}
