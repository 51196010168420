.event-card {
  padding: 1.25em;
  background: #ffffff !important;
  border: 1px solid #eff0ff;
  box-shadow: 0px 18px 47px rgba(13, 2, 130, 0.1);
  border-radius: 0.625rem;
  border: none;

  // @include flex-row-jcbetween;

  &-left {
    display: grid;
    grid-template-columns: auto auto;
  }

  .event-card-left-dot::before {
    content: "";
    position: absolute;
    height: 0.5rem;
    width: 0.5rem;
    background: #272d33;
    top: 0;
    left: -2rem;
    bottom: 0;
    margin: auto;
    border-radius: 50%;
  }

  // .event-card-left .para-5:nth-child(2)::before {
  //   content: "";
  //   position: absolute;
  //   height: 0.5rem;
  //   width: 0.5rem;
  //   background: #272d33;
  //   top: 0;
  //   left: -1em;
  //   bottom: 0;
  //   margin: auto;
  //   border-radius: 50%;
  // }

  .event-card-left .para-5:nth-child(3) {
    margin-top: 0.25em;
  }

  .event-card-left .para-5:nth-child(2) {
    position: relative;
  }

  // &:not(:last-child) {
  //   margin-bottom: 1.5em;
  // }

  &__top {
    display: grid;
    gap: unset;
  }
  &__bottom {
    @include flex-row;
    gap: 1rem;
    margin-top: 2rem;
    justify-content: flex-end;
  }
}

.eventCardBlock {
  display: flex;
  gap: 15px;
  align-items: center;
  cursor: pointer;
}

.eventCardDate {
  display: grid;
  text-align: center;
  height: 5.063em;
  width: 5.063em;
  background: #ffffff;
  border: 1px solid #eff0ff;
  border-radius: 5px;
  align-content: center;
}

.dateTitle {
  font-size: 0.938em;
  font-weight: 600;
  color: #ec0b43;
}

.dateNum {
  font-size: 1em;
  font-weight: 600;
  color: $color-16;
}

.eventInfoTop {
  display: grid;
  grid-row-gap: 0.25em;
}

.eventType {
  color: $color-16;
  font-size: 0.75em;
  font-weight: 400;
  line-height: 1;
}

.event-name {
  color: #272d33 !important;
  font-size: 1em;
  font-weight: 600;
}

.eventStartTime {
  font-size: 0.688em;
  color: #3e3f3f;
  text-align: end;
  font-weight: 500;
  margin: 0.813em 0 0;
}

.eventsModal {
  display: grid;
  position: relative;
}

.closeBtn {
  position: absolute;
  right: -3.5em;
  top: -1.2em;
  background: unset;
  border: none;
  cursor: pointer;
}

.closeBtnImg {
  height: 3em;
}

.eventsModalTitle {
  padding: 1.313em 0 1em 4rem;
  border-bottom: 1px solid #ecf0f2;
  color: #272d33;
  font-weight: 600;
  font-size: 1.5em;
}

.eventsModalInfo {
  padding: 0;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
}

.eventsModalInfo1 {
  display: grid;
  grid-row-gap: 0.5em;
}

.modalInfoTitle {
  color: $color-black;
  font-weight: 700;
  font-size: 1.25em;
  line-height: 1.3;
  padding-right: 5em;
}

.eventsModalInfo1Time {
  display: grid;
  grid-auto-flow: column;
  justify-content: flex-start;
  grid-column-gap: 1.313em;
}

.eventsModalLinkBox {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 0.375em;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
}

.eventsModalLink {
  color: #5c4fff;
  font-size: 0.813em;
  font-weight: 400;
}

.eventsModalInfo2 {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 0.813em;
}

.eventsModalLinkCopy {
  border: none;
  background: unset;
  cursor: pointer;
  outline: none;
}

.editEventBtn {
  padding: 0.75em 1em;
  background: #5c4fff;
  border-radius: 4px;
  color: #fff;
  font-size: 1em;
  align-items: center;
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 0.625em;
  align-self: center;
  height: 3em;
}

.delEventBtn {
  background: #ef4b4b;
  border-radius: 4px;
  color: #fff;
  padding: 0.75rem 0.625rem;
  font-size: 1em;
  align-items: center;
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 0.625em;
  align-self: center;
  height: 3em;
}

.eventsModalCalendarInfo {
  display: grid;
  grid-template-columns: auto auto;
  padding: 3em 0 1em;
  grid-column-gap: 2.125em;
  justify-content: flex-start;
}

.eventCalBlock {
  background: rgba(100, 115, 130, 0.07);
  border: 1px solid $color-61;
  border-radius: 4px;
  display: grid;
  padding: 0.938em 1.688em;
}

.eventCal1 {
  padding: 0.938em 7.625em;
}

.eventCal1Title {
  text-align: center;
}

.eventCalSubBlock {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: center;
  grid-column-gap: 1.688em;
  margin: 0.75em 0 0;
}

.eventBox {
  display: grid;
  text-align: center;
  grid-row-gap: 0.125em;
  background: #ffffff;
  border-radius: 4px;
  padding: 0.688em;
}

.eventBoxTimeText {
  color: $color-16;
  font-size: 0.875em;
}

.eventBoxTitle {
  color: $color-black;
  font-size: 1.25em;
  font-weight: 600;
}

.eventBtn {
  background: #dedede;
  border-radius: 2px;
  padding: 0.75em;
  display: grid;
  justify-content: center;
  align-items: center;
  border: none;
}

.startClassBtn {
  margin: 1.625em 0 0;
  color: #8e8e8e;
}

.eventCal2 {
  align-self: baseline;
  grid-row-gap: 1.375em;
}

.eventCal2Title {
  font-size: 1.125em;
  line-height: 1.2;
}

.addToCalBtn {
  color: #5c4fff;
  font-size: 1em;
}
