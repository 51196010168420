body {
  font-family: stevia-regular, "Averta CY W01";
  font-size: 1.6rem;
  line-height: 1.7rem;
  font-weight: 500;
  color: $color-6;
}

// Headings
.head {
  &-1 {
    font-size: 3.2rem;
    line-height: 4rem;
    color: $color-5;
  }
  &-2 {
    font-size: 2.4rem;
    line-height: 2.4rem;
    color: $color-6;
    font-weight: 600;

    @include responsive(small-phone) {
      font-size: 2rem;
      line-height: 2rem;
    }
  }
  &-3 {
    font-size: 4rem;
    font-size: clamp(1.5rem, calc(1rem + 3.2vw), 4rem);
    line-height: clamp(2rem, calc(1rem + 3.2vw), 4.4rem);
    color: $color-white;
  }
  &-4 {
    font-size: 2rem;
    line-height: 2.4rem;
  }
  &-5 {
    font-size: 2.4rem;
    line-height: 3.2rem;
    color: $color-6;
  }
  &-6 {
    // font-weight: 600;
    font-size: 2.4rem;
    line-height: 2.8rem;
    color: $color-white;
  }
  &-7 {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
  &-8 {
    font-size: clamp(1.5rem, calc(1rem + 3.2vw), 3.2rem);
    line-height: clamp(3.1rem, calc(1rem + 3.2vw), 4.8rem);
  }
  &-9 {
    font-size: 1.6rem;
    line-height: 3.2rem;
  }
  &-10 {
    font-size: 2.4rem;
    line-height: 4rem;
  }
  &-11 {
    font-size: 1.6rem;
    line-height: 3.2rem;
    font-weight: normal;
  }
  &-12 {
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 1.4rem;
  }
  &-13 {
    font-size: 1.6rem;
    line-height: 3.2rem;
    font-weight: 600;
  }
  &-14 {
    font-size: 1.6rem;
    line-height: 2rem;
    font-weight: 600;
    color: $color-black;
  }
  &-15 {
    font-size: 1.6rem;
    line-height: 2rem;
    font-weight: 600;
  }
  &-16 {
    font-weight: 600;
    font-size: 2.4;
    line-height: 3rem;
  }
  &-17 {
    @extend .head-7;
    font-weight: bold;
    color: $color-black;
  }
  &-18 {
    @extend .head-5;
    font-style: normal;
    font-weight: 600;
  }

  &-19 {
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: $color-6;
  }

  &-20 {
    @extend .head-19;
    font-weight: normal;
  }

  &-21 {
    @extend .head-20;
    color: $color-6;
  }
  &-22 {
    font-weight: bold;
    font-size: 1.2rem;
    line-height: 2.4rem;
    color: $color-16;
  }
  &-23 {
    @extend .head-22;
    font-weight: 400;
  }
  &-24 {
    font-weight: 600;
    font-size: 2.4rem;
    line-height: 3rem;
    color: $color-6;
  }
  &-25 {
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 3.2rem;
    color: $color-6;
  }
  &-26 {
    font-size: 2rem;
    line-height: 2.7rem;
  }
  &-27 {
    font-size: 1.8rem;
    line-height: 2.5rem;
    color: $color-50;
  }
  &-28 {
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 130%;
    color: $color-6;
    &--2 {
      line-height: 120%;
      margin-top: -0.4rem;
    }
  }
  &-29 {
    font-weight: 600;
    font-size: 3.3rem;
    line-height: 4.8rem;
    color: $color-6;
  }
  &-30 {
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 2rem;
    color: $color-6;
  }
  &-31 {
    font-weight: 600;
    font-size: 1.4rem;
    color: $color-6;
  }
  &-32 {
    font-weight: 500;
    font-size: 1.4rem;
    color: $color-6;
  }
  &-33 {
    font-weight: 600;
    font-size: 3rem;
    line-height: 4.8rem;
    color: $color-6;
  }
  &-34 {
    font-weight: 600;
    font-size: 2.4rem;
    line-height: 2.9rem;
    color: $color-6;
  }
  &-35 {
    font-weight: 600;
    font-size: 2rem;
    line-height: 2.7rem;
  }
}

// paragraphs
.para {
  &-1 {
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: $color-black;

    &--white {
      color: $color-white;
    }
    &--light {
      color: $color-16;
    }
  }

  &-2 {
    font-size: 1.6rem;
    line-height: 2rem;
    color: $color-5;

    @include responsive(small-phone) {
      font-size: 1.4rem;
      line-height: 1.8rem;
    }
  }

  &-3 {
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-weight: normal;
    color: $color-white;
  }

  &-4 {
    font-size: 1.2rem;
    line-height: 1.5rem;
    color: $color-16;
  }

  &-5 {
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: $color-16;
  }

  &-6 {
    font-size: 1.4rem;
    line-height: 1.7rem;
    color: $color-16;
  }

  &-7 {
    font-size: 1.4rem;
    line-height: 2.1rem;
    color: $color-16;
  }
  &-8 {
    font-size: 1.6rem;
    line-height: 2rem;
    color: $color-16;
  }

  &-9 {
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: $color-16;
  }

  &-10 {
    font-size: 1.2rem;
    line-height: 1.5rem;
    color: $color-16;
  }
  &-11 {
    font-size: 1.4rem;
    line-height: 2.4rem;
    color: $color-16;
  }
  &-12 {
    font-size: 1.6rem;
    line-height: 2rem;
    color: $color-16;
  }
  &-13 {
    font-size: 1.6rem;
    line-height: 2.1rem;
    color: $color-6;
  }
  &-14 {
    font-size: 1.3rem;
    line-height: 1.6rem;
    color: $color-16;
  }
  &-15 {
    font-size: 1.5rem;
    line-height: 2.8rem;
    color: $color-16;
  }
  &-16 {
    font-size: 1.2rem;
    color: $color-16;
  }
  &-17 {
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 2.4rem;
    color: $color-16;
  }
  &-18 {
    font-size: 1.2rem;
    line-height: 2.4rem;
    color: $color-16;
  }
  &-19 {
    font-size: 1.3rem;
    line-height: 2rem;
    color: $color-16;
  }
  &-20 {
    font-size: 1.4rem;
    line-height: 2.1rem;
    color: $color-6;
  }
  &-21 {
    font-size: 1.2rem;
    color: $color-16;
  }
  &-22 {
    font-size: 1.4rem;
    line-height: 2rem;
    color: $color-16;
  }
  &-23 {
    font-weight: normal;
    font-size: 1.35rem;
    color: $color-16;
  }
  &-24 {
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 2.4rem;
    color: $color-16;
  }
}

// links
