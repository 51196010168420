.meeting {
  &__card {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    border-radius: 1rem;

    padding-top: 5rem;
    padding-bottom: 5rem;
    background-image: url(../../img/pic-6.png);
    background-repeat: no-repeat;
    background-position: top right;
    background-size: cover;

    @include flex-row-jcbetween;

    @include responsive(tab-port) {
      padding: 4rem 2rem;
    }
  }

  &__card-left {
    flex-basis: 60%;
    padding-top: 2.5rem;
  }

  &__time {
    background: $color-white;
    border-radius: 1rem;
    padding: 0.5rem 1rem;
    vertical-align: middle;

    &-icon {
      width: 1.1rem;
      height: 1.1rem;
      margin-right: 0.5rem;
    }
    &-text {
      font-size: 1.2rem;
      line-height: 0;
      color: $color-28;
    }
  }
}
