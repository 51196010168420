.my-students {
  padding-top: 2rem;
}
.my-student {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid $color-29;
  @include flex-row-aicenter;
  gap: 1rem;

  &:last-of-type {
    border-bottom: none;
  }

  .image {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    object-fit: cover;
    flex-shrink: 0;
  }

  &__img {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    object-fit: cover;
  }
}

.viewAllStudentsBtn {width: 100%;text-align: center;display: grid;text-decoration: none;color: #5C4FFF;font-weight: 600;font-size: 1em;margin: 2.3em 0 0;}