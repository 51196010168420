.upcoming-classes {
  background: $color-white;
  border: 0.1rem solid $color-26;
  border-radius: 0.4rem;
  padding: 1.5rem 1rem;

  &__dropdown-content {
    @include flex-row-aicenter;
    animation: slideFromLeft 0.2s linear;
   

    span {
      margin-left: 0.8rem;
      font-size: 1.2rem;
      line-height: 2.5rem;
      color: $color-16;
    }
  }
}
