.what-to-learn {
  padding: 1.9rem 1.5rem;
  background: $color-white;
  border-radius: 0.4rem;

  &__top {
    @include flex-row-jcbetween-aistart;
  }

  &__title {
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 2rem;
  }
  &__sub-title {
    font-size: 1.2rem;
    line-height: 2.4rem;
    color: $color-16;
    margin-top: 0.5rem;
  }

  &__btn-close {
    border: none;
    background-color: transparent;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      transform: rotate(360deg);
    }
    &:active {
      border: none;
      outline: none;
    }
  }

  &__tags {
    @include flex-rowwrap;
    gap: 1.5rem;
    margin-top: 1rem;
  }

  &__tag {
    border: 1px solid $color-42;
    border-radius: 0.4rem;
    display: inline-block;
    padding: 0.7rem 2.5rem;
    user-select: none;
    text-transform: capitalize;

    font-weight: normal;
    font-size: 1.6rem;
    line-height: 2rem;
    color: $color-16;
  }
}
