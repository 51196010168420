.warning {
  background: $color-white;
  border-radius: 0.4rem;
  padding: 2rem 1.5rem;
  margin: 2rem 0 2.5rem;

  @include flex-row;
  gap: 0.5rem;
  &__icon {
    // vertical-align: middle;
    width: 2rem;
    height: 2rem;
    flex-shrink: 0;
  }
  &__text {
    display: inline-block;
    // vertical-align: middle;

    font-size: 1.6rem;
    line-height: 2rem;

    color: $color-16;
  }
}
