.notification {
  margin-bottom: 3.5rem;
  position: relative;
  cursor: pointer;
  &__icon {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
  &-label {
    display: block;
    text-align: center;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: $color-13;
  }
  &-dropdown {
    position: absolute;
    bottom: 0;
    left: 125%;
    width: 41rem;
    z-index: 9999999999999999;
    background-color: $color-white;
    height: 20rem;
  }
}
