@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap");

@font-face {
  font-family: stevia-thin;
  src: url("../../fonts/Averta-Cyrillic-Thin.otf");
}
@font-face {
  font-family: stevia-semi-bold;
  src: url("../../fonts/Averta-Cyrillic-SemiBold.otf");
}
@font-face {
  font-family: stevia-regular;
  src: url("../../fonts/Averta-Cyrillic-Regular.otf");
}
@font-face {
  font-family: stevia-light;
  src: url("../../fonts/Averta-Cyrillic-Light.otf");
}
@font-face {
  font-family: stevia-extra-bold;
  src: url("../../fonts/Averta-Cyrillic-ExtraBold.otf");
}
@font-face {
  font-family: stevia-extra-thin;
  src: url("../../fonts/Averta-Cyrillic-Extra-Thin.otf");
}
@font-face {
  font-family: stevia-bold;
  src: url("../../fonts/Averta-Cyrillic-Bold.otf");
}
@font-face {
  font-family: stevia-black;
  src: url("../../fonts/Averta-Cyrillic-Black.otf");
}
