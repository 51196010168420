.featured-course {
  &__link {
    flex-shrink: 0;
    flex-basis: 23.8rem;
  }
  &__card {
    flex-shrink: 0;
    flex-basis: 23.8rem;

    background: $color-white;
    border: 1px solid $color-26;
    box-sizing: border-box;
    border-radius: 0.4rem;

    padding: 1rem 1.3rem;
  }
  &__category {
    font-style: normal;
    font-weight: normal;
    font-size: 1.2rem;
    // line-height: 32px;
    color: $color-28;
  }
  &__title {
    font-style: normal;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 2rem;
    color: $color-6;
    max-width: 21rem;
  }
  &__avatar {
    border-radius: 0.4rem;
    overflow: hidden;
    border: 1px solid rgba(100, 115, 130, 0.1);
    height: 8rem;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  &__video-avatar {
    border-radius: 0.4rem;
    position: relative;
    // height: 10rem;
    overflow: hidden;

    // &::after {
    //   position: absolute;
    //   content: url(./../../img/pic-17.png);
    //   background-color: rgba(0, 0, 0, 0.3);
    //   width: 100%;
    //   height: 100%;
    //   border-radius: 0.4rem;
    //   display: grid;
    //   place-items: center;
    //   top: 0;
    //   left: 0;
    //   right: 0;
    //   bottom: 0;
    //   cursor: pointer;
    // }

    &.size-md {
      // height: 25rem;

      &::after {
        background-color: rgba(0, 0, 0, 0.1);
      }

      @include responsive(tab-land) {
        height: 19rem;
      }
    }
  }
  &__video-placeholder {
    // height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
