.user-notification {
  &__wrapper {
    flex-basis: 52rem;
    position: sticky;
    top: 7rem;
  }

  &__card {
    background-color: $color-white;
    border-radius: 0.7rem;
    padding: 4rem;
    @include flex-column;
    gap: 2.5rem;

    height: calc(
      100vh - (7rem + 4.8rem + 2rem)
    ); // 7rem(Top nav height) 4.8rem(Header font-size) 2rem(header text bottom margin)

    overflow-y: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__item {
    @include flex-row;
    gap: 1.5rem;
  }

  &__detail {
    font-weight: normal;
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: $color-6;
  }

  &__date {
    font-weight: normal;
    font-size: 1.2rem;
    line-height: 1.4rem;
    color: $color-16;
    display: block;
    margin-top: 1.8rem;
  }
}
