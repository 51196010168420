.students-main {
    padding-top: 2em !important;
}

.studentsInfo {
    grid-row: 2;
    grid-column: 1 / -1;
    text-align: center;
    display: grid;
    grid-row-gap: 0.625em;
}

.studentsInfo a {
    text-decoration: none;
    color: unset;
}

.studentsInfoTabBar {
    display: grid;
    grid-template-columns: 12em 12em 12em 12em 12em auto;
    grid-column-gap: 1em;
}

.student-nav-bar {
    display: grid;
    grid-auto-flow: column;
    justify-content: flex-start;
    background: #Fff;
    width: max-content;
    border-radius: 5px !important;
    z-index: 1;
}

.student-bar {
    padding: 1em 1.6em;
    color: #030229;
    font-size: 0.875em;
    cursor: pointer;
}

.student-text {
    font-size: 0.875em;
    font-weight: 400;
    color: #030229;
}

.student-bar-active {
    color: #fff;
    background: #605BFF;
    border-radius: 5px;
}

.studentInfoTab {
    align-items: center;
    justify-content: flex-start;
    grid-column-gap: 0.7em;
    padding: 1em;
    font-weight: unset;
    font-size: 0.750em;
    color: #030229;
    opacity: 0.7;
}

.infoTabIcon {
    margin-left: 0.5em;
}

.student-row {
    background: #FFFFFF;
    border-radius: 10px;
    align-items: center;
    display: grid;
    grid-template-columns: 12em 12em 12em 12em 12em auto;
    padding: 0.875em 1.25em;
    cursor: pointer;
    grid-column-gap: 0.6em;
    width: max-content;
}

.student-row:hover {
    box-shadow: 1px 17px 44px rgba(3, 2, 41, 0.07);
    border-radius: 10px;
}

.student-info {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    justify-content: flex-start;
    grid-column-gap: 0.938em;
}

.studentImg {
    height: 2.250em;
}

.more-btn {
    border: none;
    background: unset;
    padding: 0;
    align-self: center;
}

.student-detail-card {
    padding: 2rem;
    box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.05);
    border-radius: 17.97px;
}

.view-student-main {
    grid-template-columns: unset !important;
}

.student-detail-content {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    align-items: flex-start;
}

.student-detail-info {
    display: grid;
    grid-auto-flow: column;
    justify-content: flex-start;
    grid-column-gap: 2.313em;
}

.student-detail-image {
    height: 8.938em;
    width: 8.938em;
    object-fit: cover;
    border-radius: 50%;
    border: 3px solid #605BFF;
    padding: 0.2em;
}

.student-detail {
    display: grid;
    margin: 0;
    line-height: 1.5;
}

.student-detail-name {
    font-size: 2.5em;
    font-weight: 600;
    color: #000000;
    line-height: 1;
}

.student-detail-role {
    font-size: 0.813em;
    font-weight: 500;
    color: #000000;
    line-height: 1;
    margin: 1.7em 0 0;
}

.student-qualifications {
    display: grid;
    grid-auto-flow: column;
    margin: 0.875em 0 0;
    grid-column-gap: 1.531em;
}

.student-qual-box {
    display: grid;
}

.student-qual-label {
    font-size: .625em;
    color: #525252;
    font-weight: 500;
}

.student-qual-info {
    font-size: 1.125em;
    color: #000000;
    font-weight: 500;
    line-height: 1;
}

.student-qual-line {
    width: 1px;
    background: $color-black;
}

.student-detail-socials {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    justify-content: flex-start;
    margin: 0.813em 0 0;
    text-decoration: none;
    color: unset;
    grid-column-gap: .313em;
}

.student-social-icon {
    height: 1.188em;
    width: auto;
    filter: drop-shadow(0px 2.92407px 5.84814px rgba(0, 0, 0, 0.1));
    border-radius: 50%;
}

.student-social-title {color: $color-16;font-size: .750em;font-weight: 400;}

.student-detail-about {
    margin: 2.250em 0 0;
    display: grid;
    grid-row-gap: 1.188em;
}

.detail-about-label {
    font-size: 1.563em;
    font-weight: 600;
    color: #303037;
}

.detail-about-paragraph {
    font-size: 1em;
    font-weight: 400;
    color: #303037;
    line-height: 1.56;
}

.student-courses-grid {
    display: grid;
    grid-row-gap: 1.5em;
}

.student-courses-label {
    font-size: 1.688em;
    line-height: 1;
}

.student-courses-info {
    display: grid;
    grid-gap: 2.813em;
    grid-template-columns: repeat(3, 1fr);
    align-items: baseline;
}

.student-courses {border-radius: 17.9741px !important;}

.student-courses-content {
    padding: 0.813em 1.313em 1.563em !important;
    display: grid;
}

.student-top-row {
    display: grid;
    position: relative;
}

.student-top-img {
    height: 5.375em;
    width: 5.375em;
}

.student-top-title {
    margin: 0.938em 0 0;
    font-size: 0.875em;
    color: #787F85;
    line-height: 1;
    font-weight: 600;
    text-transform: uppercase;
}

.student-top-label {
    font-size: 0.750em;
    font-weight: 600;
    color: #000000;
    line-height: 2;
}

.student-top-status {
    position: absolute;
    right: 2em;
    top: 1.5em;
    background: #FFFFFF;
    box-shadow: 0px 5.39222px 12.5818px rgba(0, 0, 0, 0.1);
    border-radius: 6.29092px;
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: .375em;
    padding: .5em 1em;
}

.top-status-text {
    font-size: 1.125em;
    font-weight: 600;
    color: #000000;
}

.top-status-icon {
    width: 0.875em;
    object-fit: contain;
}

.student-mid-row {
    border-top: 0.9px solid #E0E0E0;
    display: grid;
    border-bottom: 0.9px solid #E0E0E0;
    padding: 0.618em 0;
}

.mid-row-info {
    font-size: 0.813em;
    color: #303037;
}

.student-last-row {
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 0.563em;
    justify-content: flex-start;
}

.last-row-img {
    height: 2em;
    border: 0.898703px solid #805CF4;
    border-radius: 50%;
}

.last-row-info {
    display: grid;
}

.last-row-title {font-size: 0.750em;color: #000000;font-weight: 400;}

.last-row-text {
    font-size: 0.750em;
    color: #787F85;
    font-weight: 400;
}

.students-mass {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    justify-content: flex-start;
    grid-column-gap: 4.438em;
    margin: 1.5em 0 0;
}

.student-mass-number {
    font-size: 0.813em;
    color: #EF4B4B;
    font-weight: 600;
}

.view-student-course {
    background: #191C1F;
    border-radius: 6.29092px;
    text-decoration: none;
    padding: 1em;
    color: #fff;
    border: none;
    cursor: pointer;
    margin: 1em 0 0;
    font-size: 0.875em;
}