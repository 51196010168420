*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: inherit;
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  background: $color-14;
  width: 0.6rem;
  height: 0.8rem;
}
::-webkit-scrollbar-thumb {
  background-color: $color-15;
  border-radius: 1rem;
}

html {
  font-size: 62.5%;
}

body {
  box-sizing: border-box;
}
