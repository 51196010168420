.input {
  &__radio {
    @include flex-row-jccenter-aicenter;
    &-btn {
      position: relative;

      width: 3rem;
      height: 3rem;
      border-radius: 0.4rem;

      transition: 0.4s;
      transition-timing-function: ease-in;
      border: 1px solid $color-8;

      display: grid;
      place-items: center;

      @include responsive(small-phone) {
        width: 1.8rem;
        height: 1.8rem;
        margin-right: 0.6rem;
      }

      &::before {
        position: absolute;
        content: "✔";
        // background-color: ;
        color: $color-white;
        transition: 0.4s;
      }
    }

    input[type="radio"] {
      display: none;
    }

    input[type="radio"]:checked + .input__radio-btn {
      border: 3px solid rgba(100, 115, 130, 0.2);

      &::before {
        color: $color-9;
      }
    }

    &-label {
      font-weight: bold;
      font-size: 1.4rem;
      line-height: 1.7rem;
      color: $color-3;
      transition: 0.4s;
      transition-timing-function: ease-in;

      @include responsive(small-phone) {
        font-size: 1.3rem;
        line-height: 1.6rem;
      }

      &--2 {
        font-weight: normal;
        font-size: 1.2rem;
        line-height: 1.7rem;
        color: $color-43;
        transition: 0.4s;
        transition-timing-function: ease-in;
        text-align: center;
      }
    }

    input[type="radio"]:checked ~ .input__radio-label {
      color: $color-5;
    }
  }
}
