.course-explore {
  &__top__down {
    @include flex-row-jcbetween-aicenter;
  }

  &__body {
    background: $color-white;
    border-radius: 0.4rem;
    padding: 2rem;
  }
  &__lists {
    padding-top: 4rem;
    padding-bottom: 2.5rem;
    grid-template-columns: repeat(auto-fill, minmax(Min(26.4rem, 100%), 1fr));

    // @include responsive(big-desktop) {
    //   grid-template-columns: repeat(5, 1fr);
    //   grid-row-gap: 5.5rem;
    //   padding-top: 1.5rem;
    // }

    // display: flex;
    // flex-wrap: wrap;
    // gap: 2rem;
    // padding-top: 1.5rem;

    // & > * {
    //   flex: 1 0 25rem;
    // }

    // --min: 15ch;
    // --gap: 1rem;

    display: grid;
    grid-gap: 2.5rem 1rem;
    /* min() with 100% prevents overflow
  in extra narrow spaces */
    // grid-template-columns: repeat(auto-fit, minmax(Min(100%, 25rem), 1fr));
  }

  &__search {
    @include flex-row-jcbetween;
    gap: 3rem;
    margin-bottom: 5rem;

    &-input {
      margin-inline: 0;
    }
    &-btn {
      @include flex-row-aicenter;
      gap: 1rem;

      border: 1px solid rgba(100, 115, 130, 0.1);
      border-radius: 0.4rem;
      padding: 0.5rem 3rem;

      font-size: 1.6rem;
      color: $color-16;
      background-color: $color-white;
      transition: 0.4s;

      &:hover {
        background-color: rgba(214, 214, 214, 0.1);
      }
    }
  }
}
