.featured-courses {
  @include flex-rowwrap-aicenter;
  gap: 2rem;
  background: $color-white;
  border-radius: 4px;
  padding: 1.5rem 2rem;

  overflow-x: auto;

  // display: grid;
  // grid-gap: 2.5rem 1rem;
  // grid-template-columns: repeat(auto-fill, minmax(Min(8rem, 100%), 1fr));

  &__wrapper {
    overflow: hidden;
    width: 100%;
    background-color: $color-white;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}
