.review {
  padding-top: 2rem;
  padding-bottom: 2rem;
  &__by {
    @include flex-row;
    gap: 1rem;
  }
  &__user {
    flex-shrink: 0;
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 50%;
    object-fit: cover;
    overflow: hidden;
  }
  &__user-img {
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 50%;
    object-fit: cover;
  }

  &__content {
    @include flex-row;
    gap: 1rem;
  }

  &:not(:last-child) {
    border-bottom: 1px solid $color-29;
  }
}
