.student-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;
  margin-bottom: 3rem;

  @include responsive(tab-port) {
    grid-template-columns: repeat(2, 1fr);
  }
  @include responsive(phone) {
    grid-template-columns: repeat(1, 1fr);
  }

  & > * {
    border-radius: 0.4rem;
    padding: 1.5rem 2rem;
    @include flex-row-jcbetween-aicenter;
  }

  &__total {
    background-color: $color-34;
    border: 1px solid $color-35;
  }
  &__active {
    background-color: $color-36;
    border: 1px solid $color-37;
  }
  &__completed {
    background-color: $color-38;
    border: 1px solid $color-39;
  }

  &__icon {
    max-width: 4.8rem;
    max-height: 4.8rem;
    margin-left: 1rem;
  }
  &__title {
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 1.8rem;

    &--total {
      color: $color-24;
    }
    &--active {
      color: $color-9;
    }
    &--completed {
      color: $color-41;
    }
  }

  &__value {
    font-weight: 600;
    font-size: 2.4rem;
    line-height: 2.6rem;
    color: $color-40;
    margin-top: 1rem;
  }
}
