.input {
  &__checkbox {
    input[type="checkbox"] {
      display: none;
    }
    input[type="radio"] {
      display: none;
    }

    .check {
      @include flex-row-aicenter;
      position: relative;
      cursor: pointer;

      &::before {
        position: absolute;
        content: "";
        width: 2rem;
        height: 2rem;
        font-size: 1rem;
        background-color: transparent;
        border: 1px solid $color-57;
        border-radius: 0.3rem;
        transition: 0.4s;
        transition-timing-function: ease-in;
        display: grid;
        place-items: center;
        flex-shrink: 0;
        color: $color-white;
      }

      &__label {
        font-weight: normal;
        font-size: 1.3rem;
        line-height: 1.9rem;
        color: $color-16;
        padding-left: 3rem;
        margin-top: -0.3rem;
        user-select: none;
      }
    }

    input[type="checkbox"]:checked + .check::before {
      content: "✔";
      background-color: $color-9;
    }
    input[type="radio"]:checked + .check::before {
      content: "✔";
      background-color: $color-9;
    }
  }
}
