@keyframes slideFromLeft {
  0% {
    // opacity: 0.2;
    transform: translateX(1rem);
  }
  100% {
    // opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideFromTop {
  0% {
    opacity: 0.2;
    transform: translateY(-1.5rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes slideFromLeftBounce {
  0% {
    transform: translateX(100%);
  }
  50% {
    transform: translateX(-5rem);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes zoomInOut {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0.1;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes expandFullWidth {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.animate {
  &-rotate {
    animation: rotate 1s infinite;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    transform-origin: center center;
  }
  &-rotate-2 {
    @extend .animate-rotate;
    animation-duration: 0.8s;
  }
  &-slideFromLeft {
    animation: slideFromLeft 0.6s ease;
  }
  &-slideFromLeftBounce {
    animation: slideFromLeftBounce 1s ease-out;
  }
  &-zoomInOut {
    animation: zoomInOut 0.8s ease-out;
  }
  &-fadeIn {
    animation: fadeIn 0.25s ease;
  }
  &-expandFullWidth {
    animation: expandFullWidth 0.5s ease;
  }
}
