.who-is-course-for {
  &__card {
    background-color: $color-white;
    padding-bottom: 2rem;
    border-radius: 1.2rem;
    overflow: hidden;

    .top {
      @include flex-row-jcend-aicenter;
      gap: 2rem;
      padding: 2rem;
    }
    .top-2 {
      @include flex-row-jcbetween-aicenter;
      gap: 2rem;
      border-bottom: 1px solid rgba(238, 238, 238, 0.815);
      padding: 2rem;
      padding-bottom: 1.5rem;

      &__left {
        @include flex-row;
        gap: 1rem;
      }
    }
    .body {
      padding: {
        top: 2rem;
        bottom: 2rem;
        left: 2rem;
        right: 2rem;
      }
      .body-head {
        // width: 7.242rem;
        // text-align: right;
      }
      .data {
        @include flex-row;
        gap: 1rem;
      }
    }
  }
}
