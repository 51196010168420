.course-contents {
  &__toggler {
    @include flex-row-aicenter;
    padding: 0.4rem 1.5rem;
    background: $color-23;
    border-radius: 0.4rem;
    cursor: pointer;
    user-select: none;

    width: 100%;
    font-family: inherit;
    border: none;
  }

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  &__heading {
    max-width: 30rem;
  }
}

.course-duration {
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 3re;
  margin-left: auto;
}

.course-content {
  padding: 1rem 1.5rem;
  animation: slideFromLeft 0.2s ease-in;

  &__files {
    @include flex-row;
    transition: 0.4s;
    margin-bottom: 1.3rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__file-description {
    flex-grow: 1;
    @include flex-row-jcbetween-aistart;
    margin-left: 1rem;
  }

  &__file-name {
    font-style: normal;
    font-weight: normal;
    font-size: 1.4rem;
    color: $color-16;
    text-decoration: none;
  }

  &__file-duration {
    font-weight: normal;
    font-size: 1rem;
    line-height: 2rem;
    color: $color-16;
  }

  &__action {
    @include flex-row-aistart;
    gap: 1rem;
  }

  &__action-icon {
    stroke: $color-58;
  }
}

.course-content-plain {
  @include flex-row;
  gap: 1.5rem;

  &__text {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.5rem;
    color: $color-6;
    margin-top: -.5rem;
  }

  & > svg {
    flex-shrink: 0;
  }
}
