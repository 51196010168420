.onboard {
  &__nav {
    @include flex-row-aicenter;
    padding: 4rem 0 2rem;
    position: sticky;
    top: 0;
    background-color: $background-color-1;
    z-index: 4;
  }

  &-main {
    padding-bottom: 10rem;
  }
  &-card {
    background: $color-white;
    border: 0.1rem solid $color-3;
    box-shadow: 0px 1rem 2.4rem $color-4;
    border-radius: 0.5rem;
    padding: 4rem 3.5rem;
    max-width: 48rem;
    margin-left: auto;
    margin-right: auto;

    margin-top: 8rem;

    @include responsive(small-phone) {
      padding: 3rem 2rem;
    }
  }

  &__link-reset {
    font-size: 1.2rem;
    line-height: 1.5rem;
    color: $color-6;
    display: inline-block;
    text-align: right;
    margin-bottom: 3.5rem;
    text-decoration: none;
  }

  &-question {
    margin-top: 3rem;
    border-top: 1px dashed $color-10;
    padding-top: 4rem;
    text-align: center;
  }
}
