.messages {
  display: grid;
  grid-template-columns: 29% 68%;
  grid-column-gap: 3%;
  height: 85vh;
  margin-top: auto;

  & > * {
    background-color: $color-white;
    border-radius: 0.4rem;
    height: 100%;
    overflow-y: auto;

    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__contacts {
    &-top {
      padding: 2.5rem 1rem 1.5rem 2rem;
      border-bottom: 1px solid $color-29;
    }
  }
}
.chat-card__wrapper {
  padding: 0 1.5rem 0 2rem;
  user-select: none;
  transition: 0.3s;
  cursor: pointer;

  &:not(:last-child) {
    & > * {
      border-bottom: 1px solid $color-29;
    }
  }
  &.active {
    background: linear-gradient(
      90deg,
      rgba(13, 2, 130, 0.12) 0%,
      rgba(13, 2, 130, 0) 100%
    );
  }
}
.chat-card {
  padding: 1.5rem 0 1.5rem 0;
  @include flex-row-aicenter;
}

.chat-image {
  width: 4.8rem;
  height: 4.8rem;
  border-radius: 50%;
  margin-right: 1rem;

  &__img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}

.last-message {
  font-weight: normal;
  font-size: 1.2rem;
  line-height: 101%;
  color: $color-16;
  margin-top: 0.8rem;
  display: inline-block;

  &__time {
    margin-top: 0;
  }
}

.unread-messages {
  display: grid;
  place-items: center;
  font-weight: normal;
  font-size: 1.2rem;
  line-height: 1.2rem;

  color: $color-white;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: $color-9;
  margin-top: 0.8rem;
}
.chats-active {
  @include flex-column-jcbetween;
  //   position: relative;

  &__top {
    @include flex-row-aicenter;
    padding: 2.5rem 1rem 1.5rem 2rem;
    border-bottom: 1px solid $color-29;
  }
  &__user {
    max-width: 3.2rem;
    max-height: 3.2rem;
    border-radius: 50%;
    margin-right: 1rem;
    &-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &-name {
      font-weight: 600;
      font-size: 1.6rem;
      line-height: 2rem;
    }
  }
}

.compose-message {
  //   position: fixed;
  //   bottom: 0;
  //   right: 0;

  padding: 1rem 3rem 5rem;
  &__actions {
    @include flex-row-aicenter;
  }
}

.chat-texts {
  flex-grow: 1;
  padding-top: 1rem;
  //   background-color: red;
  overflow-y: auto;
  padding-left: 3rem;
  padding-right: 3rem;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  & > * {
    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }

  &__me {
    @include flex-row-jcend;
    &-text {
      flex-basis: 75%;
      background: $color-9;
      border-radius: 2rem 2rem 0 2rem;
      color: $color-white;
      text-align: right;
      font-weight: 300;
      padding: 1.5rem;
    }
  }

  &__message {
    font-size: 1.6rem;
    line-height: 2.7rem;
  }
  &__time {
    font-size: 1.2rem;
    line-height: 1.5rem;
    display: inline-block;
    margin-top: 2rem;
  }

  &__other {
    @include flex-row;
    &-text {
      flex-basis: 75%;
      background: $color-32;
      border-radius: 0 2rem 2rem 2rem;
      text-align: left;
      font-weight: 300;
      padding: 1.5rem;
    }

    &-image {
      max-width: 3rem;
      max-height: 3rem;
      border-radius: 50%;
      margin-right: 1.5rem;
    }
    &-img {
      max-width: 100%;
      max-height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }
}

.chat-tab-list {
  display: grid;
  grid-auto-flow: column;
  height: 6.7rem;
  align-items: center;
  justify-content: space-around;
}

.chat-tab {
  display: grid;
  padding: 0.8rem;
  font-size: 1.6rem;
  color: $color-61;
}

.chat-tab.active {
  border-bottom: 0.1rem solid $color-black;
  color: $color-black;
}