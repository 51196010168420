.empty-activity-state {
  @include flex-column-jccenter-aicenter;

  &__icon {
    max-width: 13.8rem;
    max-height: 13.3rem;
  }
}

.empty-activity-box {
  @include flex-column-jccenter-aicenter;
  flex-grow: 1;
  background-color: $color-white;
}
