.action-modal {
  @include flex-column-jccenter-aicenter;
  padding: 5rem;
  gap: 2rem;
  &-icon {
    width: 3rem;
    height: 3rem;
    margin-bottom: 3rem;

    img {
      height: 100%;
      object-fit: cover;
    }
  }
  &__title-1 {
    color: $color-25;
    font-size: 2rem;
  }
  &__title-2 {
    // color: $color-25;
    font-size: 2rem;
  }
  &__actions {
    @include flex-row-jccenter-aicenter;
    gap: 2rem;
  }
}
