.spinner-box {
  padding-top: 8rem;
  padding-bottom: 15rem;

  &__text {
    font-size: 3rem;
    // font-weight: 400;
    // color: rgb(158, 158, 158);
    color: $color-9;
  }
}
