.settlement {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;
  margin-top: 5rem;

  &-table {
    margin-top: 4rem;
    &__top {
      @include flex-row-jcbetween-aicenter;
      margin-bottom: 3rem;
    }
  }

  @include responsive(tab-land) {
    grid-template-columns: repeat(2, 1fr);
  }
  @include responsive(big-phone) {
    grid-template-columns: repeat(1, 1fr);
  }
}
