.row-action {
  position: relative;

  &__button {
    cursor: pointer;
  }

  .dropdown {
    position: absolute;
    top: 1rem;
    right: 2.5rem;
    background-color: $color-white;
    border-radius: 0.6rem;
    overflow: hidden;
    filter: drop-shadow(0px 6px 24px rgba(0, 0, 0, 0.05));
    // padding: 4rem;
    width: 15rem;
    z-index: 3;

    &-btn {
      @include flex-row-aicenter;
      gap: 1.5rem;
      background-color: transparent;
      padding: 2rem;
      width: 100%;
      border-bottom: 1px solid $color-29;
      transition: 0.4s;
      &:last-of-type {
        border-bottom: none;
      }
      &-icon {
        transition: 0.4s;
      }
      &:hover {
        color: $color-9;
        .dropdown-btn-icon {
          stroke: $color-9;
        }
      }
    }
  }
}
