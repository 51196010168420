.link {
  text-decoration: none;
  display: inline-block;
  &-1 {
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: $color-9;
    transition: 0.3s;
    text-decoration: none;
  }

  &-2 {
    @include flex-column-jcbetween-aicenter;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: $color-13;
  }

  &-3 {
    color: $color-9;
  }

  &-4 {
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: $color-9;
  }
  &-5 {
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 2.4rem;
    color: $color-9;
  }

  &:hover {
    color: $color-11;
    fill: $color-11;
  }
}
