.about-instructor {
  & > * {
    padding: 2.5rem 2rem 3.5rem;
  }
  &__header {
    padding-bottom: 1rem;
    border-bottom: 1px solid $color-29;
  }

  &__courses-header {
    @include flex-row-jcbetween-aicenter;
    margin-bottom: 2rem;
  }

  &__course {
    @include flex-row-aicenter;
    gap: 1rem;

    &__icon {
    //   width: 5.9rem;
      height: 5.3rem;
      border-radius: 1rem;
      overflow: hidden;

      & > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
