.dashboard {
  &-main {
    // @include flex-row;
    // position: relative;
    display: flex;
  }

  &__main {
    flex-basis: calc(100% - 13rem);
    margin-left: 13rem;

    @include flex-column-jcbetween;
    padding: 0 0 12rem;
    // background-color: lightcoral;

    @include responsive(tab-port) {
      flex-basis: 100%;
      margin-left: 0;
      // padding-inline: 1.2rem;
    }
  }

  &__navigator {
    padding: 1.5rem 3rem;
    background-color: green;
    width: calc(100% - 13rem);
    margin-left: 13rem;
    position: fixed;
    bottom: 0;
  }
  
  &__top-nav-wrapper {
    width: 100%;
    background-color: $background-color-1;
    // background-color: red;
    position: sticky;
    top: 0;
    z-index: 6;
    padding: 2.5rem 0;
  }

  &__top-nav {
    @include flex-row-jcbetween-aicenter;
    // background-color: transparent;

    &--back-btn {
      @include flex-row-aicenter;
    }

    &--toggler {
      flex-direction: column;
      gap: 0.3rem;
      flex-basis: 2.5rem;
      border: none;
      background-color: transparent;
      flex-shrink: 0;
      display: none;

      @include responsive(tab-port) {
        display: flex;
      }
    }
    &--bars {
      display: block;
      width: 100%;
      height: 0.4rem;
      background-color: $color-9;
    }
    &__left-side {
      @include flex-row-aicenter;
      gap: 1rem;
      flex-grow: 1;
    }
    &__right {
      @include flex-row-jcbetween-aicenter;
      gap: 1rem;
      flex-grow: 1;
    }
  }

  &__main-content {
    display: grid;
    grid-template-columns: 1fr 33%;
    grid-gap: 2.5rem;
    padding-top: 5rem;
    @include responsive(tab-land-mini) {
      grid-template-columns: 1fr;
    }
  }

  &__left-side {
  }
  &__right-side {
  }
}
