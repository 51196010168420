.settlement {
  &-card {
    background: $color-white;
    border-radius: 0.4rem;
    padding: 3rem;
    @include flex-row-aicenter;

    &--1 {
      background-image: url(../../img/pic-11.png);
      background-repeat: no-repeat;
      background-position: top right;
      background-size: cover;
    }
  }

  &__icon {
    max-width: 5.1rem;
    max-height: 5.1rem;
    margin-right: 1.5rem;
  }

  &__details {
  }
  &-title {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 110%;
    color: $color-33;
    margin-bottom: 1rem;

    &--white {
      color: $color-white;
    }
  }
  &-value {
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 120%;
    color: $color-5;

    &--white {
      color: $color-white;
    }
  }
}
