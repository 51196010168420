.about-this-course {
  // margin-top: 3rem;
}

.what-to-learn-item-layout {
  @include flex-row-aicenter;
  gap: 1rem;

  & > .input__wrapper {
    flex-grow: 1;
    margin-bottom: 0;
    width: 100%;
  }

  button {
    margin-top: 0.7rem;
  }
}
