.all-course-content {
  @include flex-row;
  gap: 3rem;

  &__left {
    flex-basis: 40rem;
    flex-shrink: 0;
  }

  &__right {
    flex-grow: 1;
  }

  &__video-ele {
    // height: 31.6rem;
    background-color: $color-6;
    width: 100%;
    overflow: hidden;
    border-radius: 0.8rem;
  }
  &__doc-ele {
    padding: 3rem;
    background-color: $color-6;
    width: 100%;
    background-color: $color-59;
    border-radius: 0.8rem;
  }

  &__video-controls {
    @include flex-row-jcbetween-aicenter;
  }

  &__video-directions {
    @include flex-rowwrap;
    gap: 2rem;
  }

  &__details {
    &-card {
      border-radius: 0.8rem;
      padding-left: 4rem;
      padding-right: 4rem;
      background-color: $color-white;

      & > * {
        &:not(:last-child) {
          // border-bottom: 1px solid $color-52;
        }
      }
    }
  }

  &__detail {
    @include flex-row;
    gap: 4rem;
    padding-top: 4rem;
    padding-bottom: 4rem;

    &-title {
      flex-shrink: 0;
      flex-basis: 10rem;
    }
    &-desc {
      @include flex-column;
      gap: 1.5rem;
    }
  }

  &__doctype {
    @include flex-row-aicenter;
    gap: 0.5rem;
  }

  &__mark {
    @include flex-row-aicenter;
    gap: 1.5rem;
  }
}
