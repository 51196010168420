.create-course {
  &__layout {
    @include flex-column-jcbetween;
    width: 100%;
  }
  &__top {
    // flex-grow: 1;
    // margin-left: auto;
    // width: calc(100% - 13rem);
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &__bottom {
    background-color: $color-white;
    padding: 1.6rem 3rem;
    position: fixed;
    width: calc(100% - 13rem);
    margin-left: 13rem;
    bottom: 0;
    border-top: 1px solid $color-1;
    @include flex-row-jcbetween;

    & > * {
      flex-shrink: 0;
    }
  }
}
