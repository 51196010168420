.input {
  &__wrapper {
    &:not(:last-child) {
      margin-bottom: 2rem;
    }
  }

  &-top {
    @include flex-rowwrap-jcbetween-aicenter;
    margin-bottom: 0.7rem;
  }

  &__label {
    font-size: 1.6rem;
    line-height: 2rem;
    color: $color-6;
    @include flex-row-aicenter;

    @include responsive(small-phone) {
      font-size: 1.4rem;
      line-height: 1.8rem;
    }
  }

  &__error {
    font-size: 1.2rem;
    line-height: 1.5rem;
    color: $color-25;
    animation: slideFromLeft 0.3s ease-in-out;

    @include responsive(small-phone) {
      font-size: 1.1rem;
      line-height: 1.3rem;
    }
  }

  &__holder {
    @include flex-row-aicenter;

    background: $color-white;
    border: 1px solid $color-8;
    border-radius: 0.4rem;
    padding: 0 1.5rem 0 0;
    overflow: hidden;
    transition: 0.8s;

    @include responsive(small-phone) {
      padding: 0 1rem 0 0;
    }

    &--textarea {
      padding: 0;
    }

    &--no-icon {
      padding-right: 0;
    }

    &.focused {
      border: 1px solid $color-9;
    }
  }

  &__item {
    flex-grow: 1;
    width: 70%;
    flex-basis: 70%;
    padding: 1.5rem 0 1.5rem 1.5rem;
    margin-right: 1.5rem;
    border: none;
    font-size: 1.6rem;
    font-family: $main-font;
    background-color: transparent;

    &--no-icon {
      margin-right: 0;
    }

    &--textarea {
      resize: none;
      width: 100%;
      margin-right: 0;
      padding: 1.5rem;
    }

    &::placeholder {
      font-size: 1.4rem;
      line-height: 1.7rem;
      color: $color-7;
      font-family: $main-font;
    }

    &:focus {
      outline: none;
    }
    &-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 3rem white inset;
    }

    @include responsive(small-phone) {
      padding: 1rem 0 1rem 1rem;
      margin-right: 1rem;
    }
  }
}

.icon-transition {
  transition: 0.8s;
}

.active-icon {
  fill: $color-9;
  transition: 0.8s;
}

.icon-lock {
  cursor: pointer;
  transition: 0.8s;
}

.open-lock {
  transform: translateX(-1rem);
}

.custom-file {
  border: 1px dashed $color-61;
  border-radius: 0.4rem;
  @include flex-row-jccenter-aicenter;
  gap: 1rem;
  // padding: 5rem 1rem;
  cursor: pointer;
  position: relative;
  
  &__input {
    // width: 100%;
    // height: 100%;
    padding: 4rem;
    position: absolute;
    width: 100%;
    opacity: 0;
    overflow: hidden;
  }

  &__placeholder {
    width: 100%;
    padding: 4rem;
    cursor: pointer;
    @include flex-row-jccenter-aicenter;
    gap: 1rem;
  }
}
