.table-top {
  @include flex-row-jcbetween-aicenter;
  margin-bottom: 1.5rem;
}

.course__date-filter {
  @include flex-row-jcbetween-aicenter;
  background-color: $color-white;
  padding: 1rem;
}

.filter-date {
  margin-left: 1rem;
  margin-right: 1rem;
}

.filter__start-date,
.filter__end-date {
  font-size: 1rem;
  // line-height: 1.2rem;
  vertical-align: middle;
}

.icon-calender {
  width: 1rem;
  height: 1.1rem;
}

.icon-arrow-down {
  width: 0.6rem;
  height: 0.5rem;
}
