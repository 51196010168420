.btn {
  cursor: pointer;
  border: none;
  font-family: inherit;
  background-color: transparent;

  &__onboard-nav {
    background: $color-white;
    border: 1px solid $color-1;
    border-radius: 0.5rem;
    padding: 1.24rem 3.5rem;
    font-family: inherit;
  }

  &-1 {
    background-color: $color-9;
    border-radius: 0.4rem;
    color: $color-white;
    padding: 1.6rem;

    &:disabled {
      background-color: #5b4fff90;
      color: white;
      cursor: not-allowed;
    }

    &.fixed-width {
      width: 14.863rem;
    }

    @include responsive(tab-port) {
      padding: 1rem;
    }
  }
  &-2 {
    background: $color-22;
    border-radius: 0.2rem;
    color: $color-9;
    padding: 0.5rem 0.8rem;
    font-size: 1.4rem;
    line-height: 2.4rem;
  }

  &-3 {
    font-size: 1.6rem;
    line-height: 2rem;
    color: $color-black;
    background-color: transparent;
  }

  &-4 {
    background: $color-22;
    border-radius: 0.4rem;
    padding: 1.1rem 1.5rem;
    color: $color-9;
    font-size: 1.4rem;
  }
  &-5 {
    background: $color-9;
    border-radius: 0.4rem;
    color: $color-white;
    padding: 1.3rem 3.5rem;
    display: block;
  }

  &-6 {
    background: transparent;
    border-radius: 0.2rem;
    color: $color-9;
    background: $color-white;
    border: 1px solid $color-45;
    padding: 0.8rem 2.7rem;
    text-transform: capitalize;
    display: block;
    font-size: 1.6rem;
    line-height: 2.4rem;
  }

  &-7 {
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: $color-white;
    background-color: $color-9;
    padding: 1.2rem 2rem;

    &:disabled {
      opacity: 0.8;
      cursor: not-allowed;
    }
  }
  &-8 {
    @extend .btn-7;
    background: $color-white;
    border: 1px solid $color-45;
    border-radius: 0.2rem;
    color: $color-9;
  }

  &-naked {
    background-color: transparent;
  }

  &-upload-vid {
    padding-left: 3rem;
    padding-right: 3rem;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  &-warn {
    // background-color: $color-25;
    background-color: transparent;
    border: 1px solid $color-25;
    color: $color-25;
  }

  &-sm {
    background: $color-9;
    border-radius: 0.4rem;
    color: $color-white;
    padding: 0.7rem 2.5rem;
    display: block;
  }

  &-md {
    padding: 1rem 3.5rem;
  }

  &--full {
    width: 100%;
  }

  &:focus {
    outline: none;
  }

  &-upload-content {
    @include flex-row-aicenter;
    font-weight: normal;
    font-size: 1.6rem;
    line-height: 3.2rem;
    color: $color-9;
    font-family: inherit;
    border: none;
    background-color: transparent;
    cursor: pointer;
    margin-left: auto;
    transition: 0.3s;

    &__icon {
      max-width: 3.7rem;
      max-height: 3.7rem;
      margin-right: 0.7rem;
      transition: 0.3s;
    }

    &:hover &__icon {
      transform: scale(0.9);
    }
  }

  &-close-modals {
    display: grid;
    place-items: center;
    width: 3.2rem;
    height: 3.2rem;
    border-radius: 50%;
    background-color: $color-white;
    border: none;
    transition: 0.3s;

    position: absolute;
    top: -1.5rem;
    right: -3.9rem;
    cursor: pointer;

    &:hover {
      transform: rotate(360deg);
      transform-origin: center center;
    }
  }

  &--video-control {
    padding: 0.8rem 2.5rem;
    border-radius: 0.6rem;
  }

  &--content-card {
    padding: 0.45rem 1.5rem;
    border-radius: 0.6rem;
  }

  &--remove-item {
    background-color: transparent;
    color: $color-black;
  }
}

.editLiveBtn {
  margin-left: 1.75em;
}
