.about-course {
  padding-top: 2rem;
  &__column {
    &:not(:last-child) {
      margin-bottom: 3.5rem;
    }
  }

  &__header {
    @include flex-row-jcbetween-aicenter;
    margin-bottom: 0.6rem;
  }

  &__article {
    font-weight: normal;
    font-size: 1.4rem;
    line-height: 2.4rem;
    color: $color-16;
    margin-top: 0.9rem;
  }

  &__duration {
    font-weight: normal;
    font-size: 1.6rem;
    line-height: 2rem;
    color: $color-16;

    @include flex-row-aicenter;
    gap: 1rem;

    &__divider {
      font-weight: bold;
      font-size: 2rem;
    }
  }
}
