.pagination {
  @include flex-rowwrap-jccenter-aicenter;
  gap: 2rem;

  &__center {
    background: $color-white;
    border: 1px solid $color-26;
    border-radius: 0.5rem;
    @include flex-rowwrap-jccenter-aicenter;
    overflow: hidden;
  }
  &__item,
  &__btn {
    display: grid;
    place-items: center;
    font-size: 1.8rem;
    line-height: 2.2rem;
    color: $color-46;
    padding: 1.3rem 2.3rem;
    background-color: $color-white;
    transition: 0.5s;

    &:not(:last-child) {
      border-right: 1px solid $color-26;
    }

    &:hover,
    &.active {
      background-color: $color-9;
      color: $color-white;
    }
  }

  &__arrow{
      width: 1.8rem;
      height: 1.8rem;
  }
}
