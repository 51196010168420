.checkout-out {
  &__wrapper {
    flex-basis: 52rem;
  }

  &__card {
    background-color: $color-white;
    border-radius: 0.7rem;
    padding: 4rem;
  }

  &__billing {
    background: $color-white;
    border: 0.751334px solid $color-54;
    box-shadow: 0 1rem 1.5rem $color-55;
    border-radius: 0.7rem;
    padding: 2rem;
  }

  &__fees {
    border-bottom: 1px solid $color-29;
    padding-bottom: 1.2rem;
  }

  &__fee {
    @include flex-rowwrap;
    & > * {
      flex-basis: 50%;
    }

    h6 {
      font-weight: normal;
      font-size: 1.4rem;
      line-height: 2.4rem;
      color: $color-16;
    }
    p {
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 2.4rem;
      color: $color-53;
    }
  }

  &__total {
    @include flex-rowwrap;
    padding-top: 1.2rem;

    & > * {
      flex-basis: 50%;
    }
    h6 {
      font-weight: 600;
      font-size: 1.6rem;
      line-height: 2.4rem;
      color: $color-6;
    }
  }
}
