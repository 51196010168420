.navigator {
  @include flex-rowwrap-aicenter;

  &.with-line {
    border-bottom: 1px solid $color-29;
  }

  &__toggler {
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: $color-16;

    display: inline-block;
    cursor: pointer;
    padding-bottom: 0.7rem;
    position: relative;
    transition: 0.3s;
    user-select: none;

    &:not(:last-child) {
      margin-right: 3rem;
    }

    &:hover,
    &.active {
      color: $color-9;
    }

    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 0;
      background-color: $color-9;
      transition: 0.3s;
    }

    &:hover::after,
    &.active::after {
      height: 0.2rem;
    }
  }
}
