.student-dashboard {
  &__layout {
    display: grid;
    grid-template-columns: 73% 25%;
    grid-column-gap: 2%;
    margin-top: 4rem;

    @include responsive(tab-land-big) {
      grid-template-columns: 100%;
    }
  }
  &__right {
    // padding: 2rem;
    @include responsive(tab-land-big) {
      margin-top: 2rem;
    }
  }
  &__fix-to-top {
    position: sticky;
    top: 7.4rem;
  }
}
