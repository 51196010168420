@keyframes slideFromTop {
  0% {
    transform: translateY(-3rem);
    visibility: hidden;
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    visibility: visible;
    opacity: 1;
  }
}
@keyframes zoomIn {
  0% {
    transform: scale(0.8);
    visibility: hidden;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    visibility: visible;
    opacity: 1;
  }
}

.modal-child {
  max-width: 80rem;
  background-color: $color-white;
  border-radius: 0.5rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10rem;
  animation: zoomIn 0.3s linear both;
  animation-delay: 0.5s;
}
