.office-hours {
  padding-top: 2rem;
  &__data {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    align-items: center;
  }

  &__heading {
    font-size: 1.6rem;
    line-height: 3.2rem;
    color: $color-6;
  }

  &__duration {
    @include flex-column;
    // gap: 0.3rem;
  }
}
