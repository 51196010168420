.input {
  &-password,
  &-text {
    width: 2.5rem;
    height: 2.5rem;
  }
}

.site-logo {
  width: 10rem;
  height: 2.4rem;
}

.dashboard-side-icon {
  width: 2rem;
  height: 2rem;
  transition: 0.3s;
  // & > path {
  //   transition: 0.3s;
  // }
  // &:hover {
  //   & > path {
  //     fill: $color-9;
  //     opacity: 1;
  //   }
  //   & + span {
  //     color: $color-9;
  //   }
  // }
}

.dropdown-toggler {
  max-width: 0.9rem;
  max-height: 0.5rem;
}

.icon-file {
  max-width: 1.2rem;
  max-height: 1.5rem;
}

.icon-link {
  max-width: 1.1rem;
  max-height: 1.2rem;
}

// SVg Icons
.icon {
  &-attachment {
    max-width: 1.2rem;
    max-height: 1.2rem;
  }
  &-link-small {
    max-width: 1.1rem;
    max-height: 1.1rem;
  }
}
