$link_transition_duration: 0.6s;
.dashboard {
  &__aside {
    @include flex-column;

    height: 100vh;
    position: fixed;
    box-shadow: 0.4rem 0 1rem $color-12;
    background-color: $color-white;
    overflow-y: auto;
    padding: 2.5rem 1.5rem 4rem;
    flex-basis: 13rem;
    z-index: 9;

    &-link {
      a {
        transition: $link_transition_duration;
        &:not(:last-child) {
          margin-bottom: 3.5rem;
        }

        &:hover {
          color: $color-9;
          fill: $color-9;
        }
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }
    &::-webkit-scrollbar-thumb {
      display: none;
    }

    &.toggle{
      
    }

    @include responsive(tab-port) {
      display: none;
    }
  }

  &__side-nav-link {
    & > svg {
      & > path {
        transition: $link_transition_duration;
      }
    }
    &:hover,
    &.active {
      & > svg {
        & > * {
          fill: $color-9;
          // opacity: 1;
        }
      }
      & > span {
        color: $color-9;
      }
    }
  }
}
