.progress-bar {
  background-color: $color-30;
  border-radius: 6rem;
  position: relative;
  height: 0.6rem;

  &__status {
    border-radius: 6rem;
    width: 33%;
    background-color: $color-24;
    position: absolute;
    left: 0;
    top: 0;
    height: 0.6rem;
  }

  &__percent {
    font-weight: normal;
    font-size: 1.4rem;
    line-height: 1.7rem;
    color: $color-16;
    margin-top: 0.5rem;
    display: inline-block;
  }
}
