.table {
  padding: 1.5rem 2.5rem;
  border-radius: 1rem;
  margin-bottom: 10rem;

  @include responsive(tab-land) {
    width: 110%;
  }
  @include responsive(tab-land-mini) {
    width: 100%;
  }
  @media only screen and (max-width: 700px) {
    width: 110%;
  }
  @include responsive(phone) {
    width: 145%;
  }
  @include responsive(small-phone) {
    width: 160%;
  }

  &-wrapper {
    // display: none;
    width: 100%;
    overflow: hidden;
    background-color: $color-white;
    border-radius: 1rem;
  }

  &-container {
    width: 100%;
    overflow: auto;

    &::-webkit-scrollbar {
      display: none;
      background-color: $color-14;
      // width: 0.2rem;
      height: 0.6rem;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $color-9;
      border-radius: 1rem;
    }
  }

  &__avatar {
    width: 4.8rem;
    height: 4.8rem;
    border-radius: 1rem;
    overflow: hidden;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &__main {
    width: 100%;
    border-collapse: collapse;

    &__row {
      border-top: 1px solid $color-60;
    }

    &__data,
    &__head {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
    }

    &__head {
      font-weight: normal;
      font-size: 1.4rem;
      line-height: 1.8rem;
      color: $color-16;
      text-align: left;

      &--center {
        text-align: center;
      }

      &:not(:last-child) {
        padding-right: 2rem;
      }
    }

    &__data {
      &:not(:last-child) {
        padding-right: 1rem;
      }
    }
  }

  &__actions {
    position: relative;

    &-dots {
      cursor: pointer;
    }

    .dropdown {
      position: absolute;
      top: 3rem;
      right: 4rem;
      background-color: $color-white;
      border-radius: 0.6rem;
      overflow: hidden;
      filter: drop-shadow(0px 6px 24px rgba(0, 0, 0, 0.05));
      // padding: 4rem;
      width: 15rem;
      z-index: 3;

      &-btn {
        @include flex-row-aicenter;
        gap: 2.5rem;
        background-color: transparent;
        padding: 2rem;
        width: 100%;
        border-bottom: 1px solid $color-29;
        transition: 0.4s;
        &:last-of-type {
          border-bottom: none;
        }
        &-icon {
          transition: 0.4s;
        }
        &:hover {
          color: $color-9;
          .dropdown-btn-icon {
            stroke: $color-9;
          }
        }
      }
    }
  }
}

.data {
  &__course-name {
    @include flex-row-aicenter;
    // width: 55%;
  }

  &__course-icon {
    width: 4.8rem;
    height: 4.8rem;
    border-radius: 1rem;
    margin-right: 1rem;
  }

  &__course-img {
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    object-fit: cover;
  }

  &__course-description,
  &__details {
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: $color-6;
  }

  &__details-ref {
    text-transform: uppercase;
  }

  &__action-toggle {
    width: 4.2rem;
    height: 2rem;
    background-color: $color-23;
    border-radius: 2rem;
    position: relative;
    padding: 0.2rem 0.3rem;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
    border: none;
    transition: 0.3s;
  }

  &__action-toggler {
    width: 1.6rem;
    height: 1.6rem;
    background-color: $color-24;
    border-radius: 50%;
    position: absolute;
    top: 0.2rem;
    left: 0.3rem;
    transition: 0.3s;
  }

  &__action-toggler__active {
    left: 0.6rem;
    transform: translateX(100%);
  }

  &__published {
    font-size: 1.4rem;
    line-height: 1.7rem;
    color: $color-16;
    margin-top: 0.5rem;
    text-align: center;
    transition: 0.3s;
  }

  &__action {
    cursor: pointer;
    width: 0.3rem;
    height: 1.8rem;
  }

  &__dot {
    @include flex-row-aicenter;

    &-icon {
      max-width: 0.8rem;
      max-height: 0.8rem;
      margin-right: 0.8rem;
    }
  }
}

.course-view-button {
  width: 10.7rem;
}

.course-publish-action {
  width: 12rem;
}
