.user-profile {
  &-image__wrapper {
    @include flex-row-aicenter;
    gap: 1rem;
  }
  &-image {
    width: 12rem;
    height: 12rem;
    border-radius: 50%;
    margin-bottom: 4rem;
  }
  &__img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
  &__avatar {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    background-color: rgba(164, 191, 242, 0.33);
    display: grid;
    place-items: center;

    span {
      color: rgba(136, 136, 136, 0.987);
      font-size: 1rem;
      text-transform: uppercase;
    }
  }
}

.select-profile-image {
  position: relative;
  width: 15rem;
  height: 5rem;
  cursor: pointer;

  &__input {
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    width: 100%;
    z-index: 1;
    opacity: 0;
  }
  &__btn {
    font-weight: 600;
    font-size: 1.6rem;
    color: $color-9;
    background-color: transparent;
    @include flex-row-aicenter;
    gap: 0.5rem;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
  }
}


.input-media-link-grid {
  display: grid;
  grid-row-gap: 2rem;
}

.input-media-link-block {
  display: grid;
  grid-template-columns: auto 1fr;
  border: 0.1rem solid $color-62;
  border-radius: 0.4rem;
}

.select-media-link {
  display: grid;
  position: relative;
  padding: 0.8rem 2rem 0 0.8rem;
  align-items: center;
  border-right: 0.1rem solid $color-62;
}

.select-media-link:after {
  content: "";
  height: 0.8rem;
  width: 0.8rem;
  position: absolute;
  border-top: 0.3rem solid;
  border-right: 0.3rem solid;
  right: 1rem;
  transform: rotate(133deg);
  top: 0;
  bottom: 0;
  margin: auto;
}

.media-link {
  font-weight: 500;
  border: none;
  padding: 0 1rem;
  outline: none;
  font-size: 1.6rem;
  font-family: stevia-regular, "Averta CY W01";
}

.add-media-link {
  justify-self: flex-end;
  color: $color-9;
}

.about-me-btn {
  width: unset;
}