.copy-link {
  @include flex-row-jcbetween-aicenter;
  border: 1px solid $color-21;
  border-radius: 0.4rem;
  overflow: hidden;

  &__input {
    flex-grow: 1;
    padding-left: 1rem;
    border: none;
    height: 100%;

    font-size: 1.2rem;
    color: $color-16;
    font-family: inherit;

    &:focus {
      outline: none;
    }
  }
  &__btn {
    border-radius: 0 0.4rem 0.4rem 0;
  }
}
