.verify-email {
  &__container {
    @include flex-row-jccenter-aicenter;
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
  &__details {
    @include flex-column-jccenter-aicenter;
  }
}
